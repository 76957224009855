import { useEffect, useState } from "react";
import logo from "../../marker-logo.png";
import React from "react";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  MarkerClustererF,
} from "@react-google-maps/api";
import PropertyItem from "../PropertyItem";
import "./index.css";
import { GOOGLE_MAPS_KEY } from "../../Global/API's";

function MapComponent(props) {
  const {
    onFilterPropertiesData,
    selectedSearchValue,
    propertiesData,
    handleActiveMarker,
    activeMarker,
    setActiveMarker,
    setActiveModalId,
    isRedirect,
  } = props;
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(10);

  const onBoundChanged = () => {
    if (map !== null) {
      const bounds = map.getBounds();
      if (bounds) {
        const bounds = map.getBounds();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const nw = { lat: ne.lat(), lng: sw.lng() };
        const se = { lat: sw.lat(), lng: ne.lng() };

        const boundary = [
          { lat: sw.lat(), lng: sw.lng() },
          { lat: nw.lat, lng: nw.lng },
          { lat: ne.lat(), lng: ne.lng() },
          { lat: se.lat, lng: se.lng },
        ];
        console.log(boundary)
        if (selectedSearchValue.placeId === "ChIJ29UCh2F4gYARdR2yVhO8-wM") {
          onFilterPropertiesData(boundary);
        } else {
          const fetchCityBounds = async () => {
            const bounds = await getCityBounds(
              selectedSearchValue.lat,
              selectedSearchValue.lng
            );
            if (bounds) {
              const mapWidth =
                document.getElementById("mapComponent").offsetWidth;
              const calculatedZoom = calculateZoomLevel(bounds, mapWidth);
              setZoom(calculatedZoom);
            }
          };
          fetchCityBounds();
        }
      }
    }
  };

  const getCityBounds = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_KEY}`
      );

      const data = await response.json();
      const filteredData = data.results.filter(
        (eachData) => eachData.place_id === selectedSearchValue.placeId
      );
      if (data.status === "OK") {
        const bounds = filteredData[0].geometry.viewport;
        const ne = bounds.northeast;
        const sw = bounds.southwest;
        const nw = { lat: ne.lat, lng: sw.lng };
        const se = { lat: sw.lat, lng: ne.lng };

        const boundary = [
          { lat: sw.lat, lng: sw.lng },
          { lat: nw.lat, lng: nw.lng },
          { lat: ne.lat, lng: ne.lng },
          { lat: se.lat, lng: se.lng },
        ];
        onFilterPropertiesData(boundary);
        return bounds;
      } else {
        console.error("Geocoding error:", filteredData.status);
        return null;
      }
    } catch (error) {
      console.error("Error fetching geocoding data:", error);
      return null;
    }
  };

  const calculateZoomLevel = (bounds, mapWidth) => {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 21;

    const ne = bounds.northeast;
    const sw = bounds.southwest;

    const latFraction = (ne.lat - sw.lat) / 360;
    const lngFraction = (ne.lng - sw.lng) / 360;

    const latZoom = Math.floor(
      Math.log(mapWidth / WORLD_DIM.width / latFraction) / Math.LN2
    );
    const lngZoom = Math.min(
      Math.floor(
        Math.log(mapWidth / WORLD_DIM.height / lngFraction) / Math.LN2
      ),
      ZOOM_MAX
    );
    return Math.min(latZoom, lngZoom);
  };

  return (
    <>
      <div
        className="map-component"
        id="mapComponent"
        style={{ height: "100%", width: "100%" }}
      >
        <GoogleMap
          onLoad={(map) => {
            setMap(map);
            onBoundChanged();
          }}
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
          }}
          onBoundsChanged={onBoundChanged}
          center={selectedSearchValue}
          zoom={zoom}
          onClick={() => setActiveMarker(null)}
        >
          <MarkerClustererF>
            {(clusterer) =>
              propertiesData.map((property) => (
                <MarkerF
                  clusterer={clusterer}
                  key={property._id}
                  position={{
                    lat: Number(property.latitude),
                    lng: Number(property.longitude),
                  }}
                  onMouseOver={() => handleActiveMarker(property._id)}
                  icon={{
                    url: logo,
                    scaledSize: { width: 30, height: 38 },
                  }}
                >
                  {activeMarker === property._id ? (
                    <InfoWindowF
                      options={{ disableAutoPan: true, shouldFocus: false }}
                      onCloseClick={() => setActiveMarker(null)}
                      position={{
                        lat: Number(property.latitude),
                        lng: Number(property.longitude),
                      }}
                    >
                      <div className="info-window">
                        <PropertyItem
                          setActiveModalId={setActiveModalId}
                          isRedirect={false}
                          eachProperty={property}
                          key={property._id}
                        />
                      </div>
                    </InfoWindowF>
                  ) : null}
                </MarkerF>
              ))
            }
          </MarkerClustererF>
        </GoogleMap>
      </div>
    </>
  );
}

export default MapComponent;
